import "./bootstrap";
import "../css/app.css";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import * as Sentry from "@sentry/vue";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
import CKEditor from "@ckeditor/ckeditor5-vue";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

// For Nuxt 3
import { library, config } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import "@fortawesome/fontawesome-svg-core/styles.css";

config.autoAddCss = false;
library.add(far, fas, fab);

const appName = import.meta.env.VITE_APP_NAME || "Laravel";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(CKEditor)
            .use(VueViewer)
            .component("font-awesome-icon", FontAwesomeIcon);

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations: [Sentry.browserTracingIntegration()],
            tracesSampleRate: 1.0,
            tracePropagationTargets: ["localhost", "bi.compactorstore.com"],
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            trackComponents: true,
            logErrors: true,
        });

        return app.mount(el);
    },
    progress: {
        color: "#4B5563",
    },
});
